import * as React from 'react'
import Logo from '../images/logo.svg'
import At from '../images/at.svg'
import Medium from '../images/medium.svg'
import Linkedin from '../images/linkedin.svg'
import Cross from '../images/cross.svg'
import Hamburger from '../images/hamburger.svg'
import {Link} from 'gatsby'

interface IMenuItemProps {
	removeBorder?: boolean
	mobileBlock?: boolean
	className?: string
}

const MenuItem: React.FC<IMenuItemProps> = ({
	mobileBlock,
	removeBorder,
	children,
	className,
}) => (
	<div
		className={`
            ${mobileBlock ? 'block md:inline-flex' : 'inline-flex'}
            ${!removeBorder && 'md:border-l md:border-lightGray'}
            p-3 md:p-6 2xl:p-7
            ${className ? className : ''}
        `}
	>
		{children}
	</div>
)

interface IProps {
	siteTitle: string
}

//max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl
const Menu: React.FC<IProps> = ({siteTitle}) => {
	const [isMobileMenuClosed, setMobileMenuClosed] = React.useState(true)
	const hidden = isMobileMenuClosed ? 'hidden' : ''
	return (
		<div className='w-full fixed bg-white shadow-none md:shadow border border-gray-300 z-50 h-auto 2xl:rounded 2xl:w-7xl 2xl:mx-auto 2xl:mt-10 2xl:left-1/2 2xl:transform 2xl:-translate-x-1/2'>
			<div className='md:flex'>
				<Link to='/'>
					<MenuItem removeBorder={true} className='relative top-1 md:top-0'>
						<Logo />
					</MenuItem>
				</Link>
				<MenuItem>
					<Link to='/'>
						<span className='relative -top-1.5 md:top-0 font-medium text-1xl md:text-2xl py-4 md:py-0'>
							{siteTitle}
						</span>
					</Link>
				</MenuItem>
				{isMobileMenuClosed ? (
					<span
						className='relative top-0.5 p-5 pr-6 md:hidden float-right'
						onClick={() => setMobileMenuClosed(false)}
					>
						<Hamburger />
					</span>
				) : (
					<span
						className='relative top-0.5 p-5 pr-6 md:hidden float-right'
						onClick={() => setMobileMenuClosed(true)}
					>
						<Cross />
					</span>
				)}
			</div>
			<div className={`${hidden} md:flex border-t border-lightGray`}>
				<Link to='/people/' activeStyle={{color: '#BEBEBE'}}>
					<MenuItem
						mobileBlock={true}
						removeBorder={true}
						className='py-12 md:py-6 px-6 m-auto md:m-initial md:h-auto w-9/12 md:w-auto border-b border-lightGray md:border-b-0 md:h-initial text-center md:text-left'
					>
						People
					</MenuItem>
				</Link>
				<Link to='/firm/' activeStyle={{color: '#BEBEBE'}}>
					<MenuItem
						mobileBlock={true}
						removeBorder={true}
						className='py-12 md:py-6 px-6 m-auto md:m-initial md:h-auto w-9/12 md:w-auto border-b md:border-l border-l-0 border-lightGray md:border-b-0 md:h-initial text-center md:text-left'
					>
						Firm
					</MenuItem>
				</Link>
				{/* <Link to='/portfolio/' activeStyle={{color: '#BEBEBE'}}>
					<MenuItem
						mobileBlock={true}
						className='py-12 md:py-6 px-6 m-auto md:m-initial md:h-auto w-9/12 md:w-auto border-b border-lightGray md:border-b-0 md:h-initial text-center md:text-left'
					>
						Companies
					</MenuItem>
				</Link> */}
                {/*
				<Link to='/stories/' activeStyle={{color: '#BEBEBE'}}>
					<MenuItem
						mobileBlock={true}
						className='py-12 md:py-6 px-6 m-auto md:m-initial md:h-auto w-9/12 md:w-auto border-b border-lightGray md:border-b-0 md:h-initial text-center md:text-left'
					>
						Stories
					</MenuItem>
				</Link>
                */}
				<div className='flex justify-center py-6 md:py-0 h-auto md:h-auto md:justify-start md:inline-flex md:ml-auto'>
					<MenuItem className='px-4 pl-7'>
						<a href='mailto:team@phystech.vc'>
							<At />
						</a>
					</MenuItem>
					{/*<MenuItem removeBorder={true} className="px-3.5">*/}
					{/*  <a href="https://www.facebook.com/phystechventures"><Facebook /></a>*/}
					{/*</MenuItem>*/}
					<MenuItem removeBorder={true} className='px-4'>
						<a target='_blank' href='https://medium.com/phystechventures'>
							<Medium />
						</a>
					</MenuItem>
					<MenuItem removeBorder={true} className='px-4 pr-7'>
						<a
							target='_blank'
							href='https://www.linkedin.com/company/phystech-ventures/about/'
						>
							<Linkedin />
						</a>
					</MenuItem>
				</div>
			</div>
		</div>
	)
}

export default Menu
