/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import PropTypes from 'prop-types'
import {useStaticQuery, graphql} from 'gatsby'
import Menu from './Menu'
import {ReactElement} from 'react'

interface ILeadingMottoProps {
	icon: any
	text: any
	description?: string
	className?: string
}

export const LeadingMotto: React.FC<ILeadingMottoProps> = ({
	icon,
	className,
	text,
	description,
}) => (
	<div className={`${className ?? ''}`}>
		<div style={{height: 168 + 'px'}}>{icon}</div>
		<div className='mt-14 2xl:mt-36 text-3xl 2xl:text-4xl leading-header'>
			{text}
		</div>
		{description ? <span>{description}</span> : ''}
	</div>
)

interface IBlockProps {
	className?: string
	nestedClassName?: string
	color: string
}

export const Block: React.FC<IBlockProps> = ({
	children,
	className,
	nestedClassName,
	color,
}) => (
	<div
		className={`bg-${color} border-b border-lightGray ${
			className ? className : ''
		}`}
	>
		<div
			className={`max-w-7xl mx-auto ${nestedClassName ? nestedClassName : ''}`}
		>
			{children}
		</div>
	</div>
)

interface IProps {}

const Layout: React.FC<IProps> = ({children}) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<div className={'font-body'}>
			<Menu siteTitle={data.site.siteMetadata?.title || `Title`} />
			{children}
		</div>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
